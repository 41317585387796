import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    margin: 60px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`

const Wrapper = styled.div`
    max-width: 500px;
` 

const Title = styled.h2`
    text-transform: uppercase;
    margin-bottom: 50px;
`

const Text = styled.p`
    font-size: 14px;
`

export default function ParagraphWithTitle({title, texts}) {
    return (
        <Container>
            <Wrapper>
                <Title>{title}</Title>
                {texts.map((text, i)=> <Text key={i}>{text}</Text>)}
            </Wrapper>
        </Container>
    )
}
