import React, { useContext } from 'react'
import Layout from '../../../components/layout'
import DotDivider from '../../../components/layout/DotDivider';
import Footer from '../../../components/layout/Footer';
import HeroContainer from '../../../components/layout/HeroContainer'
import Offer from '../../../components/layout/Offer';
import ParagraphWithTitle from '../../../components/layout/ParagraphWithTitle';
import SimilarOffers from '../../../components/layout/SimilarOffers';
import SEO from '../../../components/seo';
import { AppContext } from '../../../contexts/AppContext';

export default function PublicCharging() {

    const { appState } = useContext(AppContext);

    return (
        <Layout>
            <SEO title={appState.i18n.publicCharging.title} />
            <HeroContainer  title={appState.i18n.publicCharging.title} subtitle={appState.i18n.publicCharging.text} id="public-charging" />
            <DotDivider />
            <ParagraphWithTitle title={appState.i18n.publicCharging.ceme} texts={[appState.i18n.publicCharging.cemeParagraph1, appState.i18n.publicCharging.cemeParagraph2]} />
            <SimilarOffers >
                <Offer link={"/solutions/plans/mobility"} title={appState.i18n.similarOffers.mobilityPlanTitle} text={appState.i18n.similarOffers.mobilityPlanText}/>
                <Offer link={"/solutions/plans/residential"} title={appState.i18n.similarOffers.residentialPlanTitle} text={appState.i18n.similarOffers.residentialPlanText}/>
            </SimilarOffers>
            <DotDivider />
            <ParagraphWithTitle title={appState.i18n.publicCharging.opc} texts={[appState.i18n.publicCharging.opcParagraph1, appState.i18n.publicCharging.opcParagraph2]} />
            <Footer />
        </Layout>

    )
}
