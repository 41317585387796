import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext'

const Container = styled.div`
    margin: 60px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Wrapper = styled.div`
    width: 100%;
    max-width: 950px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`

const Title = styled.h2`
    text-transform: uppercase;
    max-width: 600px;
    text-align: center;
`

export default function SimilarOffers({children, title}) {
    const { appState } = useContext(AppContext);

    return (
        <Container>
            {title && <Title>{title}</Title>}
            <Wrapper>
                {children}
            </Wrapper>
        </Container>
    )
}
