import { Link } from 'gatsby'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext'
import DefaultButton from '../common/Button'
import {shadow} from '../../styles/theme'

const Container = styled.div`
    ${shadow}
    max-width: 200px;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
`

const Content = styled.div`
    height: 200px;
`

const Title = styled.h3`
    text-transform: uppercase;
`

const Text = styled.p`
    font-size: 14px;
    font-weight: 300;
`

const Nav = styled.div`
`



export default function Offer({title, text, link}) {
    const { appState, sendEvent } = useContext(AppContext);

    return (
        <Container onClick={() => sendEvent("Similar Offer Tile", title, window.location.pathname)}>
            <Content>
                <Title>{title}</Title>
                <Text>{text}</Text>
            </Content>
            <Nav>
                <Link to={link}>
                    <DefaultButton label={appState.i18n.all.seeMore} />
                </Link>
            </Nav>            
        </Container>
    )
}
