import React from 'react'
import styled from 'styled-components'

const FullWidth = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
`

const Dot = styled.div`
    width: 30px;
    height: 8px;
    border-radius: 5px;
    background-color: ${props => props.theme.default.colors.lightGrey };
    margin: 0 5px;
`

export default function DotDivider() {
    return (
        <FullWidth>
            <Dot />
        </FullWidth>
    )
}
